import { arithmetic } from './formula.calc.utils'

const chevrotain = require('chevrotain')
let createToken = chevrotain.createToken

const concatTokens = function() {
  var arr = []
  for(let i = 0; i < arguments.length; i++) {
    arguments[i] && (arr = arr.concat(arguments[i]))
  }

  return arr
}

const sortTokensByPosition = function(t) {
  return [].concat(t).sort((t, e) => {
      return t.startOffset - e.startOffset
  })
}

const consumeBinaryToken = function(t, e, i){
  var a, n, s = t.slice(), o = e.slice(), r = t.map(function(t) {
      return t.image
  }).indexOf(i.image)

  if(r > -1) {
    a = (n = o.slice().splice(r, 2))[0],
    e = n[1],
    t = null,
    (n = chevrotain.tokenMatcher)(i, baseToken.Addition) || n(i, baseToken.Multiplication) ? t = arithmetic(i.image, a, e) : n(i, baseToken.Compare) ? t = n(i, baseToken.LessOrEqual) ? a <= e : n(i, baseToken.GreaterOrEqual) ? e <= a : n(i, baseToken.Less) ? a < e : e < a : n(i, baseToken.Equal) ? t = n(i, baseToken.EqualStrict) ? a === e : n(i, baseToken.NotEqualStrict) ? a !== e : n(i, baseToken.EqualLoose) ? a == e : a != e : n(i, baseToken.BitAnd) ? t = a & e : n(i, baseToken.BitOr) ? t = a | e : n(i, baseToken.LogicAnd) ? t = a && e : n(i, baseToken.LogicOr) && (t = a || e),
    s.splice(r, 1),
    o.splice(r, 2, t)
  }

  return {
    resultTokens: s,
    resultOperands: o
  }
}

const getBaseToken = () => {
  let lexer = chevrotain.Lexer

  let Unary = createToken({ name: 'Unary', pattern: lexer.NA }),
  Addition = createToken({ name: "Addition", pattern: lexer.NA }),
  Multiplication = createToken({ name: "Multiplication", pattern: lexer.NA }),
  Equal = createToken({ name: "Equal", pattern: lexer.NA }),
  Compare = createToken({ name: "Compare", pattern: lexer.NA })

  return {
    WhiteSpace: createToken({ name: "WhiteSpace", pattern: /\s+/, group: lexer.SKIPPED }),
    String: createToken({ name: "String", pattern: /("(\\\\|\\"|[^"])*"|'(\\\\|\\'|[^'])*')/ }),
    Number: createToken({ name: "Number", pattern: /[0-9]+(\.[0-9]+)?(e[+-]?[0-9]+)?/ }),
    Boolean: createToken({ name: "Boolean", pattern: /(true|false)/ }),
    Empty: createToken({ name: "Empty", pattern: /(null|undefined)/ }),
    NaN: createToken({ name: "NaN", pattern: /NaN/ }),
    Comma: createToken({ name: "Comma", pattern: /,/ }),
    Func: createToken({ name: "Func", pattern: /[A-Z0-9]+/ }),
    LParen: createToken({ name: "LParen", pattern: /\(/ }),
    RParen: createToken({ name: "RParen", pattern: /\)/ }),
    LSquare: createToken({ name: "LSquare", pattern: /\[/ }),
    RSquare: createToken({ name: "RSquare", pattern: /]/ }),
    Plus: createToken({ name: "Plus", pattern: /\+(?!\+)/, categories: [Unary, Addition] }),
    Minus: createToken({ name: "Minus", pattern: /-(?!-)/, categories: [Unary, Addition] }),
    Unary: Unary,
    Addition: Addition,
    Multi: createToken({ name: "Multi", pattern: /\*/, categories: [Multiplication] }),
    Div: createToken({ name: "Div", pattern: /\//, categories: [Multiplication] }),
    Multiplication: Multiplication,
    LessOrEqual: createToken({ name: "LessOrEqual", pattern: /<=/, categories: [Compare] }),
    GreaterOrEqual: createToken({ name: "GreaterOrEqual", pattern: />=/, categories: [Compare] }),
    Less: createToken({ name: "Less", pattern: /</, categories: [Compare] }),
    Greater: createToken({ name: "Greater", pattern: />/, categories: [Compare] }),
    Compare: Compare,
    EqualStrict: createToken({ name: "EqualStrict", pattern: /===/, categories: [Equal] }),
    NotEqualStrict: createToken({ name: "NotEqualStrict", pattern: /!==/,        categories: [Equal] }),
    EqualLoose: createToken({ name: "EqualLoose", pattern: /=/, categories: [Equal] }),
    NotEqualLoose: createToken({ name: "NotEqualLoose", pattern: /!=/, categories: [Equal] }),
    Equal: Equal,
    LogicNot: createToken({ name: "LogicNot", pattern: /!/, categories: [Unary] }),
    LogicAnd: createToken({ name: "LogicAnd", pattern: /&&/ }),
    LogicOr: createToken({ name: "LogicOr", pattern: /\|\|/ }),
    BitAnd: createToken({ name: "BitAnd", pattern: /&/ }),
    BitOr: createToken({ name: "BitOr", pattern: /\|/ })
  }
}

const getAllTokens = () => {  
  return [baseToken.WhiteSpace, baseToken.String, baseToken.Number, baseToken.NaN, baseToken.Boolean, baseToken.Empty, 
    baseToken.Func, baseToken.LParen, baseToken.RParen, baseToken.LSquare, baseToken.RSquare, baseToken.Plus, 
    baseToken.Minus, baseToken.Addition, baseToken.Unary, baseToken.Multi, baseToken.Div, baseToken.Multiplication, 
    baseToken.LessOrEqual, baseToken.GreaterOrEqual, baseToken.Less, baseToken.Greater, baseToken.Compare, 
    baseToken.EqualStrict, baseToken.NotEqualStrict, baseToken.EqualLoose, baseToken.NotEqualLoose, baseToken.Equal, 
    baseToken.LogicNot, baseToken.LogicAnd, baseToken.LogicOr, baseToken.BitAnd, baseToken.BitOr, baseToken.Comma]
}

const baseToken = getBaseToken()
const allTokens = getAllTokens()

export const getLexer = function() {
  return new chevrotain.Lexer(allTokens, {
    ensureOptimizations: true
  })
}

export const getParser = function() {
  let parser = chevrotain.CstParser

  function parserFunc() {
    parser.call(this, allTokens)
    let intc = this

    intc.RULE("expr", function() {
      intc.SUBRULE(intc.commaExpression)
    })

    intc.RULE("commaExpression", function() {
      intc.SUBRULE(intc.binaryExpression)
      intc.MANY(function() {
        intc.CONSUME(baseToken.Comma),
        intc.SUBRULE2(intc.binaryExpression)
      })
    })

    intc.RULE("binaryExpression", function() {
      intc.SUBRULE(intc.unaryExpression)
      intc.MANY(function() {
        intc.OR(intc.c1 || (intc.c1 = [
          { ALT: function() { intc.CONSUME(baseToken.LogicOr) }}, 
          { ALT: function() { intc.CONSUME(baseToken.LogicAnd) }}, 
          { ALT: function() { intc.CONSUME(baseToken.BitOr) }}, 
          { ALT: function() { intc.CONSUME(baseToken.BitAnd) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Equal) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Compare) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Addition) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Multiplication) }}
        ]))
        intc.SUBRULE2(intc.unaryExpression)
      })
    })

    intc.RULE("unaryExpression", function() {
      intc.MANY(function() {
        intc.CONSUME(baseToken.Unary)
      })
      intc.SUBRULE(intc.atomicExpression)
    })

    intc.RULE("atomicExpression", function() {
      intc.OR(intc.c2 || (intc.c2 = [
        { ALT: function() { intc.SUBRULE(intc.array) }},
        { ALT: function() { intc.SUBRULE(intc.func) }}, 
        { ALT: function() { intc.SUBRULE(intc.group) }}, 
        { ALT: function() { intc.SUBRULE(intc.base) }}
      ]))

      intc.MANY(function() {
        intc.CONSUME(baseToken.LSquare)
        intc.SUBRULE(intc.expr)
        intc.CONSUME(baseToken.RSquare)
      })
    })

    intc.RULE("array", function() {
      intc.CONSUME(baseToken.LSquare)
      intc.MANY_SEP({
        SEP: baseToken.Comma,
        DEF: function() {
          intc.SUBRULE(intc.binaryExpression)
        }
      })
      intc.CONSUME(baseToken.RSquare)
    })

    intc.RULE("func", function() {
      intc.CONSUME(baseToken.Func)
      intc.CONSUME(baseToken.LParen)
      intc.OPTION(function() {
        intc.SUBRULE(intc.binaryExpression)
        intc.MANY(function() {
          intc.CONSUME(baseToken.Comma)
          intc.SUBRULE2(intc.binaryExpression)
        })
        intc.OPTION2(function() {
          intc.CONSUME2(baseToken.Comma)
        })
      })
      intc.CONSUME(baseToken.RParen)
    })

    intc.RULE("group", function() {
      intc.CONSUME(baseToken.LParen)
      intc.AT_LEAST_ONE_SEP({ SEP: baseToken.Comma, DEF: function() { intc.SUBRULE(intc.expr) }})
      intc.CONSUME(baseToken.RParen)
    }),
    intc.RULE("base", function() {
      intc.OR(intc.c3 || (intc.c3 = [
          { ALT: function() { intc.CONSUME(baseToken.String) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Number) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Boolean) }}, 
          { ALT: function() { intc.CONSUME(baseToken.Empty) }}, 
          { ALT: function() { intc.CONSUME(baseToken.NaN) }}
        ]
      ))
    })

    this.performSelfAnalysis()
  }

  return new ((parserFunc.prototype = Object.create(parser.prototype)).constructor = parserFunc)
}

export const  getInterpreter = function() {
  let $this = this, visior = this.parser.getBaseCstVisitorConstructor()

  function interpreter() {
    visior.apply(this, arguments)
    this.validateVisitor()
  }

  (interpreter.prototype = Object.create(visior.prototype)).constructor = interpreter
  let tokenMatcher = chevrotain.tokenMatcher
  
  interpreter.prototype.expr = function(t) {
    return this.visit(t.commaExpression)
  }
  interpreter.prototype.commaExpression = function(t) {
    t = t.binaryExpression.slice(-1)[0]
    return this.visit(t)
  }
  interpreter.prototype.binaryExpression = function(t) {
    let $this = this
    let tokens = concatTokens(t.Multiplication, t.Addition, t.Compare, t.Equal, t.BitAnd, t.BitOr, t.LogicAnd, t.LogicOr)
      , a = sortTokensByPosition(tokens)
      , n = []
    
    if(Array.isArray(t.unaryExpression)) {
      t.unaryExpression.forEach(e => {
        n.push($this.visit(e))
      })
    }
    
    tokens.forEach(token => {
      token = consumeBinaryToken(a, n, token)
      a = token.resultTokens,
      n = token.resultOperands
    })

    return n[0]        
  }
  interpreter.prototype.unaryExpression = function(t) {
    let i = this.visit(t.atomicExpression)
    if (!t.Unary) return i
    t = t.Unary.slice().reverse()
    t.forEach(e => {
      i = tokenMatcher(e, baseToken.Minus) ? -i : tokenMatcher(e, baseToken.Plus) ? +i : !i
    })

    return i
  }
  interpreter.prototype.atomicExpression = function(t) {
    let i = null
    t.base ? i = this.visit(t.base) : t.group ? i = this.visit(t.group) : t.array ? i = this.visit(t.array) : t.func && (i = this.visit(t.func))
    
    if(Array.isArray(t.expr)) {
      t.expr.forEach(e => {
        e = this.visit(e)
        i = i[e]
      })
    }

    return i
  }
  interpreter.prototype.array = function(t) {
    let i = []
    if(Array.isArray(t.binaryExpression)) {
      t.binaryExpression.forEach(e => {
        i.push(this.visit(e))
      })
    }

    return i
  }
  interpreter.prototype.func = function(t) {
    let e = t.Func[0].image, a = []
    
    if(Array.isArray(t.binaryExpression)) {
      t.binaryExpression.forEach(e => {
        a.push(this.visit(e))
      })
    }

    return $this.calcParser.applyFunc(e, a)
  }
  interpreter.prototype.group = function(t) {
    return this.visit(t.expr)
  }
  interpreter.prototype.base = function(t) {
    if (t.String) {
      let e = t.String[0].image
      return e.substring(1, e.length - 1)
    }
    return t.Number ? Number(t.Number[0].image) : t.Boolean ? "true" === t.Boolean[0].image : t.Empty ? null : t.NaN ? NaN : void 0
  }

  return new interpreter
}