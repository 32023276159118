const Big = require('big.js')
import utils from '@/assets/script/utils'

const C_NUM_ARR = ['零', '壹', '貳', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
const C_BIT_ARR = ['', '拾', '佰', '仟'];
const C_UNIT_ARR = ['', '万', '亿', '兆'];

export const isValidNumber = (num) => {
  return typeof num == "number" && !isNaN(num) && isFinite(num)
}
//四则运算
export const arithmetic = (op, left, right) => {
  let calcOps = ["+", "-", "*", "/"], result
  
  if(!isValidNumber(left) && utils.isNumeric(left)) left = Number(left)
  if(!isValidNumber(right) && utils.isNumeric(right)) right = Number(right)

  if (op === "!") {
    return eval(op + "left")
  }

  if (calcOps.indexOf(op) > -1 && isValidNumber(left) && isValidNumber(right)) {
    switch (op) {
    case "+":
        result = new Big(left).plus(right)
        break
    case "-":
        result = new Big(left).minus(right)
        break
    case "*":
        result = new Big(left).times(right)
        break
    case "/":
        result = right === 0 ? "" : new Big(left).div(right)
    }

    return result === ""  ? null : Number(result.toString())
  }

  return eval("left" + op + "right")
}
// 数字转中文
export const numberToChinese = (money) => {
  
  //最大处理的数字
  let maxNum = 999999999999999.9999;
  //金额整数部分
  let integerNum;
  //金额小数部分
  let decimalNum;
  //输出的中文金额字符串
  let chineseStr = '';
  //分离金额后用的数组，预定义
  let parts;
  if (money == '') return ''

  money = parseFloat(money);
  if (money >= maxNum) return ''

  if (money == 0) return C_NUM_ARR[0];
  //转换为字符串
  money = money.toString();
  if (money.indexOf('.') == -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    let IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += C_NUM_ARR[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += C_NUM_ARR[parseInt(n)] + C_BIT_ARR[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += C_UNIT_ARR[q];
      }
    }
  }
  //小数部分
  if (decimalNum != '') {
    chineseStr += '点';
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      chineseStr += C_NUM_ARR[Number(n)];
    }
  }

  return chineseStr;


}