var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-layout" },
    [
      _vm._l(_vm.data, function (d) {
        return [
          !_vm.collapsedMap[d.id]
            ? _c(
                "div",
                {
                  key: d.id,
                  staticClass: "gantt-node-row",
                  class: { week: _vm.bus.viewType == _vm.ViewType.Week },
                  style: d.style,
                  on: {
                    mouseenter: function ($event) {
                      return _vm.rowActive($event)
                    },
                    mouseleave: _vm.rowLeave,
                  },
                },
                [
                  _c("gantt-node", {
                    attrs: {
                      data: d,
                      barColor: _vm.barColor,
                      bus: _vm.bus,
                      activeRowPos: _vm.activeRowPos,
                    },
                    on: {
                      "update:activeRowPos": function ($event) {
                        _vm.activeRowPos = $event
                      },
                      "update:active-row-pos": function ($event) {
                        _vm.activeRowPos = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }