const Big = require('big.js')
import utils from '@/assets/script/utils'
import dayjs from 'dayjs'
import store from '@/store'

import { arithmetic, numberToChinese } from './formula.calc.utils'
import { EnumBusType } from '@/config/enum.config'
import URL from '@/interface/index'

import {formatNumber} from '../../components/Dashboard/Chart/dataHandler';

const BASE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const SyncRequest = (url, params, type) => {
  let reqType = !!type ? type : 'POST'
  // 需要同步请求返回recno并赋值在options.recno
  let oReq = new XMLHttpRequest()
  // 同步请求
  oReq.open(reqType, url, false)
  // 请求头参数
  let token = localStorage.getItem('login-token')
  let region = localStorage.getItem('login-region')
  let tenant = localStorage.getItem('login-tenant') || ''
  let user = JSON.parse(localStorage.getItem('login-user'))
  let commonHeaders = { token, userid: user.id, tenant, regionId: region }
  for (const key in commonHeaders) {
    if (commonHeaders.hasOwnProperty(key)) {
      const value = commonHeaders[key];
      oReq.setRequestHeader(key, value)
    }
  }
  oReq.setRequestHeader("Content-type","application/json")
  
  let data = !!params ? JSON.stringify(params) : JSON.stringify({})
  oReq.send(data)

  return JSON.parse(oReq.responseText)
}

let FunctionHandle = {
  //日期函数
  TODATE: function(t) {
    if(FuncUtils.isString(t) && dayjs(t).isValid() && t.split(' ').length == 1) t += ' 00:00:00'

    return dayjs(new Date(t)).format(BASE_DATE_FORMAT);
  },
  DATE: function() {
    if(arguments.length == 6) {
      return dayjs(new Date(parseInt(arguments[0], 10),parseInt(arguments[1], 10) - 1, parseInt(arguments[2], 10), parseInt(arguments[3], 10), parseInt(arguments[4], 10),parseInt(arguments[5], 10))).format(BASE_DATE_FORMAT) 
    } else if(arguments.length == 3) {
      return dayjs(new Date(parseInt(arguments[0], 10),parseInt(arguments[1], 10) - 1,parseInt(arguments[2], 10))).format(BASE_DATE_FORMAT)
    } else if(arguments.length == 0) {
      return dayjs(new Date).format(BASE_DATE_FORMAT)
    } else {
      let params = arguments[0]
      if(FuncUtils.isString(params) && params.length == '') return ''
      else if(FuncUtils.isString(params) && dayjs(params).isValid() && params.split(' ').length == 1) params += ' 00:00:00'

      return dayjs(new Date(params)).format(BASE_DATE_FORMAT)
    }
  },
  DAYADD: function(date, days) {
    let time = date.split(' '), format = 'YYYY-MM-DD'
    if(time[1]) format += ' HH:mm:ss'

    return dayjs(date).add(days, 'day').format(format)
  },
  SECONDADD: function(date, seconds) {
    return dayjs(date).add(seconds, 'second').format('YYYY-MM-DD HH:mm:ss')
  },
  MINUTEADD: function(date, minutes) {
    return dayjs(date).add(minutes, 'minute').format('YYYY-MM-DD HH:mm:ss')
  },
  HOURADD: function(date, hours) {
    return dayjs(date).add(hours, 'hour').format('YYYY-MM-DD HH:mm:ss')
  },
  MONTHADD: function(date, months) {
    let time = date.split(' '), format = 'YYYY-MM-DD'
    if(time[1]) format += ' HH:mm:ss'

    return dayjs(date).add(months, 'month').format(format)
  },
  YEARADD: function(date, years) {
    let time = date.split(' '), format = 'YYYY-MM-DD'
    if(time[1]) format += ' HH:mm:ss'

    return dayjs(date).add(years, 'year').format(format)
  },
  DAY: function(t) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t))
      return t.getDate()
  },  
  DAYS: function(t, e) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t)) {
      e = FuncUtils.parseDate(e);
      if (!FuncUtils.isNull(e)){
        let end = new Date(t.getFullYear(),t.getMonth(),t.getDate()),
            start = new Date(e.getFullYear(),e.getMonth(),e.getDate());

        return ( end - start ) / 864e5;
      }
    }
  },
  HOUR: function(t) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t))
        return t.getHours()
  },
  HOURS: function(end, start) {
    let startT = FuncUtils.parseDate(start),
        endT = FuncUtils.parseDate(end);

    if(!FuncUtils.isNull(startT) && !FuncUtils.isNull(endT)){
      let time = new Date(endT) - new Date(startT);
      if(time > 0){
        return time / 1000 / 60 / 60;
      }
    }
  },
  MINUTE: function(t) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t))
        return t.getMinutes()
  },
  MINUTES: function(end, start) {
    let startT = FuncUtils.parseDate(start),
        endT = FuncUtils.parseDate(end);

    if(!FuncUtils.isNull(startT) && !FuncUtils.isNull(endT)){
      let time = new Date(endT) - new Date(startT)
      if(time > 0){
        return time / 1000 / 60;
      }
    }
  },
  MONTH: function(t) {
    t = FuncUtils.parseDate(t)
    if (!FuncUtils.isNull(t))
        return t.getMonth() + 1
  },
  MONTHS: function(end, start) {
    return dayjs(end).diff(dayjs(start), 'month')
  },
  NOW: function() {
    return dayjs().format('YYYY-MM-DD HH:mm:ss')
  },
  SECOND: function(t) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t))
        return t.getSeconds()
  },
  SECONDS: function(end, start) {
    let startT = FuncUtils.parseDate(start),
        endT = FuncUtils.parseDate(end);

    if(!FuncUtils.isNull(startT) && !FuncUtils.isNull(endT)){
      let time = new Date(endT) - new Date(startT);
      if(time > 0){
        return time / 1000;
      }
    }
  },
  TIME: function(t, e, i) {
    return (3600 * t + 60 * e + i) / 86400
  },
  TIMESTAMP: function(t) {

    let date = FuncUtils.parseDate(t);
    return !FuncUtils.isNull(date) ? date.getTime() : 0
  },
  TODAY: function() {
    return dayjs().format('YYYY-MM-DD')
  },
  WEEK: function(t) {
    let today = new Date(t);
    let firstDay = new Date(today.getFullYear(),0, 1);
    let dayOfWeek = firstDay.getDay(); 
    let spendDay= 1;
    if (dayOfWeek !=0) {
      spendDay = 7 - dayOfWeek + 1;
    }
    firstDay = new Date(today.getFullYear(),0, 1+spendDay);
    let d = Math.ceil((today.valueOf()- firstDay.valueOf())/ 86400000);
    let result = Math.ceil(d/7);
    return result+1;  
  },
  YEAR: function(t) {
    t = FuncUtils.parseDate(t);
    if (!FuncUtils.isNull(t))
        return t.getFullYear()
  },
  YEARS: function(end, start) {

    let endYear = dayjs(end).year(),
        startYear = dayjs(start).year();

    return endYear - startYear;
  },
  NETWORKDAYS: function(t, e, i) {

    if (t = FuncUtils.parseDate(t),
    e = FuncUtils.parseDate(e),
    FuncUtils.isNull(t) || FuncUtils.isNull(e))
        return null;
    // 校验日期是否合法
    if(!dayjs(t).isValid() || !dayjs(e).isValid()) return null;

    var a, n = false
    FuncUtils.isBeforeDate(e, t) && (n = true, a = t, t = e, e = a);
    for (var s = FuncUtils.isWorkDay(t, i) ? 1 : 0, o = t; FuncUtils.isBeforeDate(o, e); )
        o = new Date(o.getTime() + 864e5),
        FuncUtils.isWorkDay(o, i) && (s += 1);
    return s = n ? FuncUtils.parseNumber(s) : s
  },
  WORKDAY: function(t, e, i) {
    if (t = FuncUtils.parseDate(t),
    FuncUtils.isNull(t))
        return null;
    if (!(e = FuncUtils.parseNumber(e)))
        return t;
    var a = t.getTime()
      , n = 864e5;
    for (0 < e || (n = -n),
    e = Math.abs(e); 0 < e; )
        a += n,
        FuncUtils.isWorkDay(a, i) && e--;
    return FuncUtils.date2Str(new Date(new Date(FuncUtils.parseDate(a)).toLocaleDateString()));
  },
  SYSTIME: function() {
    let dT = store.getters.diffTimeStamp || 0
    let sysTime = dayjs().valueOf() + dT
    
    return dayjs(sysTime).format('YYYY-MM-DD HH:mm:ss')
  },
  //逻辑函数
  IFS: function() {
    for (var t = null, e = 0; e < arguments.length; e += 2)
        if (arguments[e]) {
            var i = arguments[e + 1]
            t = FuncUtils.isNull(i) ? null : i;
            break
        }
    return t
  },
  AND: function() {
    for (var t = FuncUtils.flatten(arguments), e = 0, i = t.length; e < i; e++)
      if (!t[e])
        return false
    return true
  },
  FALSE: function() {
    return false
  },
  IF: function(t, e, i) {
    return t ? e : i
  },
  NOT: function(t) {
    return !t
  },
  OR: function() {
    for (var t = FuncUtils.flatten(arguments), e = 0, i = t.length; e < i; e++)
      if (t[e])
        return true
    return false
  },
  TRUE: function() {
    return true
  },
  XOR: function() {
    for (var t = 0, e = FuncUtils.flatten(arguments), i = 0, a = e.length; i < a; i++)
        e[i] && t++;
    return !!(1 & Math.floor(Math.abs(t)))
  },
  //数学函数
  ABS: function(t) {
    return FuncUtils.isNumber(t) ? Math.abs(t) : 0
  },
  AVG: function() {
    for (var t = FuncUtils.flatten(arguments, function(t) {
        return FuncUtils.isNumber(t)
    }), e = t.length, i = 0, a = 0, n = 0; n < e; n++)
        i += FuncUtils.parseNumber(t[n]),
        a += 1;
    return i / a
  },
  CEILING: function(t, e) {
    if (t = FuncUtils.parseNumber(t),
    e = FuncUtils.parseNumber(e),
    FuncUtils.isNull(t) || FuncUtils.isNull(e))
        return null;
    if (0 === e)
        return 0;
    var i = e < 0 ? -1 : 0;
    e = Math.abs(e);
    var a = arithmetic("-", e, Math.floor(e))
      , n = 0;
    return 0 < a && (n = -Math.floor(arithmetic("/", Math.log(a), Math.log(10)))),
    0 <= t ? FunctionHandle.ROUND.apply(this, [arithmetic("*", Math.ceil(arithmetic("/", t, e)), e), n]) : 0 == i ? -FunctionHandle.ROUND.apply(this, [arithmetic("*", Math.floor(arithmetic("/", Math.abs(t), e)), e), n]) : -FunctionHandle.ROUND.apply(this, [arithmetic("*", Math.ceil(arithmetic("/", Math.abs(t), e)), e), n])
  },
  COUNT: function() {
    let c = 0
    FuncUtils.flatten(arguments, function(arg) {
      if(utils.unValidate(arg)) return

      if(Array.isArray(arg)) c += arg.length
      else if(Object.prototype.toString.call(arg) == "[object String]") {
        let arr = arg.split(',')
        c += arr.length
      }
      else c++
    })

    return c
  },
  FIXED: function(t, e) {
    t = FuncUtils.parseNumber(t);

    if(!FuncUtils.isNull(t)){
      e = FuncUtils.parseNumber(e)
    }

    if(!FuncUtils.isNull(e)){
      return FuncUtils.toFixed(t, e)
    }else{
      return t;
    }
  },
  FLOOR: function(t, e) {
    if (t = FuncUtils.parseNumber(t),
    e = FuncUtils.parseNumber(e),
    FuncUtils.isNull(t) || FuncUtils.isNull(e))
      return null;
    if (0 === e)
      return 0;
    if (!(0 < t && 0 < e || t < 0 && e < 0))
      return 'NUM';
    e = Math.abs(e);
    var i = arithmetic("-", e, Math.floor(e)), a = 0;

    0 < i && (a = -Math.floor(arithmetic("/", Math.log(i), Math.log(10))))
    
    return 0 <= t ? FunctionHandle.ROUND.apply(this, [arithmetic("*", Math.floor(arithmetic("/", t, e)), e), a]) : -FunctionHandle.ROUND.apply(this, [arithmetic("*", Math.floor(arithmetic("/", Math.abs(t), e)), e), a])
  },
  INT: function(t) {
    return FuncUtils.isNumber(t) ? parseInt(t) : 0
  },
  LARGE: function(t, e) {
    e = (t = FuncUtils.flatten(t, function(t) {
        return FuncUtils.isNumber(t)
    })).sort(function(t, e) {
        return e - t
    })[e - 1];
    return FuncUtils.parseNumber(e)
  },
  LOG: function(t, e) {
    e = void 0 === e ? 10 : e
    return FuncUtils.isNumber(e) ? Math.log(t) / Math.log(e) : 0
  },
  MAX: function() {
    let isDate = false
    var t = FuncUtils.flatten(arguments, function(t) {
      if(FuncUtils.isString(t)) {
        isDate = true
        return !isNaN(FuncUtils.parseDate(t).getTime())
      } else return FuncUtils.isNumber(t)
    })

    if(isDate && t.length === 0) return null

    t = t.map(v => {
      if(FuncUtils.isString(v)) {
        isDate = true
        return FuncUtils.parseDate(v).getTime()
      } else return v
    })

    let res = 0 === t.length ? 0 : Math.max.apply(Math, t)

    return isDate ? dayjs(res).format('YYYY-MM-DD HH:mm:ss') : res
  },
  MIN: function() {
    let isDate = false
    var t = FuncUtils.flatten(arguments, function(t) {
      if(FuncUtils.isString(t)) {
        isDate = true
        return !isNaN(FuncUtils.parseDate(t).getTime())
      } else return FuncUtils.isNumber(t)
    })

    if(isDate && t.length === 0) return null

    t = t.map(v => {
      if(FuncUtils.isString(v)) {
        isDate = true
        return FuncUtils.parseDate(v).getTime()
      } else return v
    })

    let res = 0 === t.length ? 0 : Math.min.apply(Math, t)

    return isDate ? dayjs(res).format('YYYY-MM-DD HH:mm:ss') : res
  },
  MOD: function(t, e) {
    if (0 === e)
      return 0;
    t = Math.abs(t % e);
    return 0 < e ? t : -t
  },
  POWER: function(t, e) {
    e = Math.pow(t, e);
    return isNaN(e) ? 0 : e
  },
  PRODUCT: function() {
    var t = FuncUtils.flatten(arguments, function(t) {
      return FuncUtils.isNumber(t)
    }), e = 1;
    if (t.length)
      for (var i = 0; i < t.length; i++)
          e = arithmetic("*", e, t[i]);
    else
      e = 0;
    return e
  },
  ROUND: function(t, e) {
    if (t = FuncUtils.parseNumber(t),
    e = FuncUtils.parseNumber(e),
    FuncUtils.isNull(t) || FuncUtils.isNull(e))
        return null;
    t = arithmetic("*", t, Math.pow(10, e));
    return Number(new Big(t).round(0).toString()) / Math.pow(10, e)
  },
  SMALL: function(t, e) {
    e = (t = FuncUtils.flatten(t, function(t) {
        return FuncUtils.isNumber(t)
    })).sort(function(t, e) {
        return t - e
    })[e - 1];
    return FuncUtils.parseNumber(e)
  },
  SQRT: function(t) {
    return t < 0 ? 0 : Math.sqrt(t)
  },
  SUM: function() {
    for (var t = 0, e = FuncUtils.flatten(arguments, function(t) {
      return FuncUtils.isNumber(t)
    }), i = 0, a = e.length; i < a; ++i)
      t = arithmetic("+", t, FuncUtils.parseNumber(e[i]));
    return t
  },
  SUMPRODUCT: function() {
    for (var t, e, i, a = 0, n = [], s = -1, o = 0; o < arguments.length; o++)
      arguments[o]instanceof Array && (s = s < 0 ? arguments[o].length : Math.min(arguments[o].length, s),
      n.push(arguments[o]));
    for (var r = 0; r < s; r++) {
      for (t = 1,
      e = 0; e < n.length; e++)
          i = parseFloat(n[e][r]),
          isNaN(i) && (i = 0),
          t = arithmetic("*", t, i);
      a = arithmetic("+", a, t)
    }
    return a
  },
  //文本函数
  CONCAT: function() {
    let arg = [...arguments];
    return arg.join(''); 
  },
  CHAR: function(t) {
    return 9 === t || 10 === t || 34 === t || 39 === t || 92 === t ? String.fromCharCode(t) : ""
  },
  EXACT: function(t, e) {
    return t === e
  },
  ISEMPTY: function(t) {

    if(typeof t == 'string') return t === '';
    if(Array.isArray(t)) return t.length === 0;
    if(typeof t == 'object' && !Array.isArray(t)) return Object.keys(t).length === 0;

    return false;
  },
  LEFT: function(t, e) {
    e = FuncUtils.isEmpty(e) ? 1 : e
    return t ? t.substring(0, e) : ""
  },
  LEN: function(t) {
    return FuncUtils.isString(t) ? t ? t.length : 0 : t && t.length ? t.length : 0
  },
  LOWER: function(t) {
    return FuncUtils.isString(t) ? t && t.toLowerCase() : ""
  },
  MID: function(t, e, i) {
    t = t || ""
    return FuncUtils.isNumber(e) && FuncUtils.isNumber(i) ? t.substr(e - 1, i) : t
  },
  REPLACE: function(t, e, i, a) {
    return FuncUtils.isNumber(e) && FuncUtils.isNumber(i) 
      ? (a = a || "", (t = t || "").substr(0, e - 1) + a + t.substr(e - 1 + i)) : t
  },
  REPT: function(t, e) {
    e = e || 0
    return  new Array(e + 1).join(t)
  },
  RIGHT: function(t, e) {
    return e = void 0 === e ? 1 : e,
      t ? t.substring(t.length - e) : ""
  },
  SEARCH: function(e, t) {
    return FuncUtils.isString(t) && FuncUtils.isString(e) 
      ? (t.toLowerCase().indexOf(e.toLowerCase()) + 1) : 0
  },
  SPLIT: function(t, e, i) {

    let arr = FuncUtils.isString(t) ? t.split(e) : [];

    return i ? arr[i - 1] : arr;
  },
  SPLITALL: function(t, e) {
    return FuncUtils.isString(t) ? t.split(e) : []
  },
  TEXT: function(t, e) {

    let dateTime = new Date(t).getTime();

    return FuncUtils.isNull(t) ? "" : 
      !isNaN(dateTime) && !FuncUtils.isEmpty(e) && e.indexOf('y') > -1 ? FuncUtils.date2Str(t, e) : 
        FuncUtils.num2Str(t, e)
  },
  TRIM: function(t) {
    return FuncUtils.isString(t) ? t.replace(/ +/g, " ").trim() : ""
  },
  UPPER: function(t) {
    return FuncUtils.isString(t) ? t.toUpperCase() : ""
  },
  VALUE: function(t) {
    if(t === '') return '';
    return FuncUtils.isEmpty(t) || isNaN(t) ? 0 : parseFloat(t)
  },
  STRING: function(t) {
    return FuncUtils.isEmpty(t) ? '' : String(t);
  },
  CONTAINS: function(t, e) {
    let str = String(t),
        checkStr = String(e);
    
    return str.indexOf(checkStr) > -1;
  },
  NUMBER: function(t) {
    return FuncUtils.isEmpty(t) || isNaN(t) ? 0 : Number(t)
  },
  UPPERMONEY: function(t) {
    return numberToChinese(t)
  },
  ARRAYJOIN: function(t, e) {
    let split = e !== null && e !== undefined ? e : ','

    let res = ''
    if(Array.isArray(t)) {
      res = t.join(split)
    }

    return res
  },
  ARRAYUNIQUE: function(t) {
    let arr = []
    if(Array.isArray(t)) {
      arr = t
    }
    
    return arr.distinct()
  },
  //高级函数
  UUID: function() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)

      return v.toString(16)
    })
  },
  GETENTERNAME: function() {
    let regionCode = store.getters.currentRegion,
      region = store.getters.regionList.find(d => d.code == regionCode)
    
    return region ? region.name : ''
  },
  GETUSERNAME: function() {
    let user = JSON.parse(localStorage.getItem('login-user'))
    return user ? user.realName : ''
  },
  RECNO: function() {
    let recno = this.options.recno
    
    if(recno && Number(recno) > 0) {
      return recno
    } else {
      let url = URL.business.instance.recno
      if(this.options.type == EnumBusType.SmartForm) {
        url = URL.business.instance.smartformRecno
      }

      let res = SyncRequest(url, { describeApiName: this.options.apiName })
      recno = this.options.type == EnumBusType.SmartForm ? res.data.data.incId : res.data.incId

      // recno = Number(recno)
      
      // 同步recno至表单
      if(this.options.recno) this.options.recno = recno
      // 初始化时可能不存在parent
      if(this.options.parent && this.options.parent.recno) this.options.parent.recno = recno

      return recno
    }
  },
  LOOKUP: function(operation, mapValue, mapField, resultField) {
    //配置无效,返回
    if(utils.unValidate(operation) || utils.unValidate(mapField) || utils.unValidate(resultField)) return ''
    if(String(mapField).indexOf('object_') == -1) return ''
    if(String(resultField).indexOf('object_') == -1) return ''
    
    mapValue = mapValue !== undefined ? mapValue : ''
    let url = URL.business.business.lookup,
      params = { 
        operation, 
        mapValue, 
        mapField: mapField.replace(/#/ig, ''), 
        resultField: resultField.replace(/#/ig, '')
      }
    if(this.options.projectId) params.projectId = this.options.projectId
    if(this.options.projectField) params.projectId = this.options.projectField

    let res = SyncRequest(url, params)
    return res.success ? res.data : ''
  }
}

export const FuncUtils = {
  flatten: (data, cb, res) => {
    res = res || []
    if (data) {
      for (let i = 0, length = data.length; i < length; i++) {
        let s = data[i]
        Array.isArray(s) ? FuncUtils.flatten(s, cb, res) : cb && !cb(s) || res.push(s)
      }
    }

    return res
  },
  isEmpty: (t) => {    
    if (null == t) return true
    if (Array.isArray(t) && 0 < t.length) return false
    if (Array.isArray(t) && 0 === t.length) return true

    for (var e in t) {
      if (hasOwnProperty.call(t, e))
      return false
    }

    return isNaN(t)
  },
  isString: (t) => {
    return typeof t == "string"
  },
  isNumber: (t) => {
    return utils.isNumeric(t)
  },
  isNull: (t) => {
    return t == null
  },
  isDate: (t) => {
    return t instanceof Date
  },
  parseDate: (t) => {
    return new Date(t)
  },
  parseNumber: (t) => {
    t = parseFloat(t)
    return !isNaN(t) && isFinite(t) ? t : null
  },
  date2Str: (t, format = 'YYYY-MM-DD') => {

    format = format.replace(/y/g, 'Y').replace(/d/g, 'D');

    return dayjs(t).format(format)
  },
  num2Str: (t, e) => {
    if (FuncUtils.isEmpty(t)) return ""

    let i = t + ""
    if (FuncUtils.isEmpty(e)) return i

    return formatNumber(t, e, '0');
  },
  toFixed: function(t, e) {
    t = FuncUtils.parseNumber(t)
    return FuncUtils.isEmpty(t) ? "" : Number(new Big(t).toFixed(e))
  },
  isWeekEnd: function(t) {
    if (!FuncUtils.isDate(t))
        return false
    t = t.getDay();
    return 0 === t || 6 === t
  },
  isWorkDay: function(i, t) {
    if (i = FuncUtils.parseDate(i),
    FuncUtils.isNull(i))
        return false
    var a = true
    (a = FuncUtils.isWeekEnd(i) ? false : a)

    if(Array.isArray(t)) {
      t.forEach(function(day, e) {
        let dayInfo = new Date(day);
        if (FuncUtils.isSameDate(i, dayInfo)) a = false;
      })
    }

    return a
  },
  isSameDate: function(t, e) {
    return !(!FuncUtils.isDate(t) || !FuncUtils.isDate(e)) && (t.getFullYear() === e.getFullYear() && t.getMonth() === e.getMonth() && t.getDate() === e.getDate())
  },
  isBeforeDate: function(t, e) {
      return !(!FuncUtils.isDate(t) || !FuncUtils.isDate(e) || FuncUtils.isSameDate(t, e)) && t.getTime() < e.getTime()
  },
}

export default FunctionHandle