var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gantt-grid",
      on: {
        mouseover: function ($event) {
          _vm.isSelfScroll = true
        },
        mouseout: function ($event) {
          _vm.isSelfScroll = false
        },
      },
    },
    [
      _c(
        "vxe-table",
        {
          ref: "grid",
          attrs: {
            height: "100%",
            "scroll-y": { gt: 50 },
            "tree-config": _vm.bus.grid.treeConfig,
            "row-class-name": _vm.bus.grid.rowClass,
            "show-header-overflow": "",
            "auto-resize": "",
            "highlight-hover-row": "",
            resizable: true,
            "show-overflow": "",
            data: _vm.data,
          },
          on: {
            scroll: _vm.onScroll,
            "cell-click": _vm.cellClick,
            "toggle-tree-expand": _vm.onRowExpand,
            "cell-mouseenter": _vm.cellEnter,
            "cell-mouseleave": _vm.cellLeave,
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _vm.bus.isReady && !_vm.bus.isloading && _vm.data.length == 0
                    ? _c("section", { staticClass: "empty" }, [
                        _c("div", { staticClass: "empty-message" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.groupColumn
            ? _c("vxe-table-column", {
                attrs: {
                  field: _vm.groupColumn.prop,
                  "show-overflow": true,
                  title: " ",
                  width: 45,
                  align: "center",
                  "cell-render": {
                    name: _vm.groupColumn.prop,
                    events: _vm.groupColumn.events,
                  },
                  "tree-node": true,
                },
              })
            : _c("vxe-table-column", {
                attrs: {
                  type: "seq",
                  width: 45,
                  title: " ",
                  "show-header-overflow": false,
                },
              }),
          _vm._l(_vm.columns, function (column, cIndex) {
            return [
              _c("vxe-table-column", {
                key: cIndex,
                attrs: {
                  field: column.prop,
                  align: column.align || "left",
                  title: column.label,
                  width: _vm.columnWidth(column),
                  "class-name": cIndex == 0 ? "first-cell" : "",
                  "cell-render": { name: column.prop, events: column.events },
                },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }