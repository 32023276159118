










import Vue, { PropType } from 'vue'
import GanttNode from './gantt-node.vue'
import { GanttLayoutData, Bus, ViewType } from '@/plugins/gantt/utils/types'

export default Vue.extend({
  name: 'GanttLayout',
  components: { GanttNode },
  props: {
    data: {
      type: Array as PropType<GanttLayoutData>,
      required: true,
    },
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    activeRowPos: {
      type: Number,
      required: true
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  data() {
    return {
      ViewType,
      collapsedMap: {}
    }
  },
  created() {
    const { ee } = this.bus
    ee.on(ee.Event.ExpandNode, this.onExpandNode)
  },
  methods: {
    rowActive(ev) {
      let rowY = ev.target.getClientRects()[0].y,
        parentY = ev.target.parentNode.parentNode.getClientRects()[0].y

      this.$emit('onActiveRow', rowY - parentY)
    },
    rowLeave() {
      this.$emit('onLeaveRow')
    },
    onExpandNode(ids, expanded) {
      ids.forEach(id => this.collapsedMap[id] = !expanded)
      this.$forceUpdate()
    }
  }
})
