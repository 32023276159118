

























import Vue, { PropType } from 'vue'
import GanttGroup from './gantt-group.vue'
import GanttLeaf from './gantt-leaf.vue'
import GanttMilestone from './gantt-milestone.vue'
import { GanttLayoutNode, Bus, ViewType } from '@/plugins/gantt/utils/types'
import { isGroup, isMilestone } from '@/plugins/gantt/utils'

interface Style {
  transform: string
  width: string
  height: string
}

function dividedBy(offsetX: number, colW: number) {
  return Math.round(offsetX / colW)
}

export default Vue.extend({
  name: 'GanttNode',
  props: {
    data: {
      type: Object as PropType<GanttLayoutNode>,
      required: true,
    },
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    activeRowPos: {
      type: Number,
      required: true
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  data: () => ({
    dragData: {
      dragging: false,
      offsetX: 0
    },
    resizeData: {
      resizing: false,
      offsetX: 0,
      category: '' //start:左侧移动,end: 右侧移动
    },
    focusing: false,
    hovering: false,
    otherWorking: false,
    isClicking: false //是否点击标记,mousedown时true,一旦draggle或resize时就终止-置为false,mouseup时判断并$emit
  }),
  computed: {
    component(): Vue.VueConstructor {
      if (isGroup(this.data)) return GanttGroup
      if (isMilestone(this.data)) return GanttMilestone
      return GanttLeaf
    },
    dataInPx(): { x: number; y: number; w: number; h: number } {
      const { rowH, colW } = this.bus
      const { x, y, w, h } = this.data
      const { dragData, resizeData } = this
      // 里程碑中心需要对齐当天最右边，而非中间
      const milestoneOffset = isMilestone(this.data) ? colW / 2 : 0
      const resizeStartX = resizeData.category == 'start' ? resizeData.offsetX : 0
      const resizeEndX = resizeData.category == 'end' ? resizeData.offsetX : -resizeData.offsetX 

      return {
        x: x * colW + dragData.offsetX + milestoneOffset + resizeStartX,
        y: y * rowH,
        w: w != 0 ? Math.max(colW, w * colW + resizeEndX) : 0,
        h: h * rowH,
      }
    },
    style(): Style {
      const { x, y, w, h } = this.dataInPx
      let rx = x
      if(isMilestone(this.data)) rx--
      return {
        transform: `translateX(${rx}px)`,
        width: w + 'px',
        height: h + 'px',
      }
    },
    absolutePosition() {
      let { x, y } = (this as any).dataInPx
      const { rowH } = this.bus
      let node: any = this.$parent
      // 统计到顶级 layout 的距离
      while (node.$options.name !== 'GanttChart') {
        if (node.$options.name === 'GanttLayout') {
          y += rowH
        } else if (node.$options.name === 'GanttNode') {
          x += node.dataInPx.x
          y += node.dataInPx.y
        }
        node = node.$parent
      }

      return { x, y }
    },
    isMonthView() {
      return this.bus.viewType === ViewType.Month
    },
    nodeState() {
      if(!this.bus.enableDraggle) return 'disabled'
      return this.hovering ? 'hovering' : ''
    },
    cptBarColor() {
      return this.data && this.data.color ? this.data.color : this.barColor
    }
  },
  created() {
    const { ee } = this.bus
    ee.on(ee.Event.ScrollToNode, (id: string) => {
      this.isClicking = false
      
      if (id !== this.data.id) return
      if(this.data.w == 0) return

      const { x, y } = this.absolutePosition

      ee.emit(ee.Event.ScrollTo, { x: x - 200, y: y })
      this.startFocus(null)
    })
    ee.on(ee.Event.Focus, (id: string) => {
      if (id !== this.data.id) {
        this.blur()
      } else {
        this.focus()
      }
    })

    ee.on(ee.Event.DragStart, ({ id }: { id: string }) => {
      this.otherWorking = id !== this.data.id
    })
    ee.on(ee.Event.DragEnd, () => {
      this.otherWorking = false
    })

    ee.on(ee.Event.ResizeStart, ({ id }: { id: string }) => {
      this.otherWorking = id !== this.data.id
    })
    ee.on(ee.Event.ResizeEnd, () => {
      this.otherWorking = false
    })
  },
  methods: {
    onDragStart(e: MouseEvent) {
      this.isClicking = true

      if(!this.bus.enableDraggle) return
      if(this.data.disableDraggle) return
      
      this.dragData.dragging = true
      this.dragData.offsetX = 0
      const { ee } = this.bus
      ee.emit(ee.Event.DragStart, { id: this.data.id })
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.onDragEnd)
      document.documentElement.classList.add('move')
    },
    onDrag(e: MouseEvent) {
      this.isClicking = false
      this.dragData.offsetX += e.movementX
      
      const { ee } = this.bus
      ee.emit(ee.Event.Drag, {
        movedCols: dividedBy(this.dragData.offsetX, this.bus.colW),
        dataInPx: {
          ...this.dataInPx,
          ...this.absolutePosition,
        },
      })
    },
    onDragEnd() {
      document.removeEventListener('mousemove', this.onDrag)
      document.removeEventListener('mouseup', this.onDragEnd)
      document.documentElement.classList.remove('move')
      this.dragData.dragging = false
      this.dragData.offsetX = 0
      const { ee } = this.bus
      ee.emit(ee.Event.DragEnd)
    },
    onResizeStart(category: any, e: MouseEvent) {
      if(category == 'start' && !this.bus.enableResizeStart) return
      if(category == 'end' && !this.bus.enableResizeEnd) return

      this.resizeData.resizing = true
      this.resizeData.offsetX = 0
      this.resizeData.category = category

      const { ee } = this.bus
      ee.emit(ee.Event.ResizeStart, { id: this.data.id })
      document.addEventListener('mousemove', this.onResize)
      document.addEventListener('mouseup', this.onResizeEnd)
      document.documentElement.classList.add('col-resizing')
    },
    onResize(e: MouseEvent) {
      this.isClicking = false
      let tx = this.resizeData.offsetX + e.movementX
      //判断拖拽是否溢出
      //1.左侧触发元素&&向右拖拽&&抵达左侧单元格
      //2.右侧触发元素&&向左拖拽&&抵达右侧单元格
      if((this.resizeData.category  == 'start' && tx > 0
          && ((tx + this.bus.colW) > this.data.w * this.bus.colW)) 
        || (this.resizeData.category == 'end' && tx < 0
          && ((tx + this.data.w * this.bus.colW) < this.bus.colW))) {
        this.onResizeEnd()
        return
      }
      
      this.resizeData.offsetX += e.movementX
      const { ee } = this.bus
      const movex = this.resizeData.category == 'start' ? 1 - this.data.x : 1 - this.data.w
      ee.emit(ee.Event.Resize, {
        resizedCols: Math.max(
          movex, // 至少一列
          dividedBy(this.resizeData.offsetX, this.bus.colW),
        ),
        dataInPx: this.dataInPx,
        category: this.resizeData.category
      })
    },
    onResizeEnd() {
      document.removeEventListener('mousemove', this.onResize)
      document.removeEventListener('mouseup', this.onResizeEnd)
      document.documentElement.classList.remove('col-resizing')
      this.resizeData.resizing = false
      this.resizeData.offsetX = 0
      this.resizeData.category = ''
      const { ee } = this.bus
      ee.emit(ee.Event.ResizeEnd)
    },
    /**
     * 通知其他节点 blur & 自身 focus
     */
    startFocus(ev: any) {
      if(this.isClicking == false) return

      const { ee } = this.bus
      ee.emit(ee.Event.Focus, this.data, ev)
    },
    startHover(ev:any) {
      // 其他节点正在 drag 或者 resize 的时候，不能 hover
      if (this.otherWorking) return

      const { ee } = this.bus
      ee.emit(ee.Event.StartHover, {
        id: this.data.id,
        w: this.dataInPx.w,
        ev: ev,
        ...this.absolutePosition,
      })

      this.hovering = true
    },
    endHover() {
      const { ee } = this.bus
      ee.emit(ee.Event.EndHover)

      this.hovering = false
    },
    focus() {
      this.focusing = true
      document.addEventListener('click', this.blur)
    },
    blur() {
      this.focusing = false
      document.removeEventListener('click', this.blur)
    }
  }
})
