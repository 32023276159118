
























































































































import Vue, { PropType } from 'vue'
import _uniq from 'lodash.uniq'
import GanttLayout from './gantt-layout.vue'
import {
  GanttData,
  GanttLayoutData,
  GanttNode,
  CollapsedMap,
  Bus,
  ViewType,
  GanttMilestone,
  HoveringNode,
} from '@/plugins/gantt/utils/types'
import { isGroup, isMilestone, search } from '@/plugins/gantt/utils'
import dayjs from '@/plugins/gantt/utils/day'
import { DayData, getWeekdays, isRestDay } from '@/plugins/gantt/utils/weekday'

const viewTypeOptions = [
  {
    label: '天',
    value: ViewType.Day,
  },
  {
    label: '周',
    value: ViewType.Week,
  },
  /*{
    label: '月',
    value: ViewType.Month,
  },*/
]

const dateMinWidth = {
  milestone: 100,
  other: 160,
}

function transform(
  ganttData: GanttData,
  dates: string[],
  collapsedMap: CollapsedMap,
  px = 0, // layout 容器在日期列中的起始位置
) {
  // HACK: 如果用 layoutData = ganttData.map 会导致 get y() 中找不到 layoutData
  const layoutData: GanttLayoutData = []
  ganttData.forEach((d, i) => {
    const xInDates = dates.indexOf(isMilestone(d) ? d.date : d.startDate)
    if (xInDates === -1) {
      //console.error('时间不在日期列中', d) // 有可能是格式不对
    }
    const base = {
      id: d.id,
      name: d.name,
      disableDraggle: !!d.disableDraggle,
      instance: d.instance,
      style: d.style,
      color: d.color,
      x: xInDates != -1 ? xInDates - px : 0,
      get y(): number {
        if (i === 0) return 0
        const { y, h } = layoutData[i - 1]
        return y + h
      },
    }
    
    if (isGroup(d)) {
      let width = dayjs.$duration(d.startDate, d.endDate)
      const g = {
        // ...base, // 很奇怪，这里一用展开运算符，h 的响应式就会失效，似乎代码在别的环境跑没问题
        id: d.id,
        name: d.name,
        x: xInDates - px,
        disableDraggle: !!d.disableDraggle,
        instance: d.instance,
        color: d.color,
        get y(): number {
          if (i === 0) return 0
          const { y, h } = layoutData[i - 1]
          return y + h
        },
        get h(): number {
          if (collapsedMap[this.id]) return 1
          return this.children.reduce((h: number, item) => {
            return h + item.h
          }, 1)
        },
        w: width >= 0 ? width : 0,
        progress: d.progress,
        children: transform(d.children, dates, collapsedMap, xInDates),
      }
      layoutData.push(g)
    } else if (isMilestone(d)) {
      layoutData.push({
        ...base,
        w: 1,
        h: 1,
        done: d.done,
      })
    } else {
      let width = dayjs.$duration(d.startDate, d.endDate)
      layoutData.push({
        ...base,
        w: width >= 0 ? width : 0,
        h: 1,
        progress: d.progress,
      })
    }
  })

  return layoutData
}

interface ContentStyle {
  width: string
  backgroundSize: string
}

interface MonthStyle {
  width: string
}
interface WeekStyle {
  width: string
}
/**
 * 根据以下条件，返回列的时间范围
 * - 所有节点的最早开始时间 & 最晚结束时间
 * - 列模式
 */
function getRange(data: GanttData, viewType: ViewType = ViewType.Day, enableExpendToNow: Boolean) {
  let startDate: string | undefined
  let endDate: string | undefined
  function loop(d: GanttData | GanttNode) {
    if (Array.isArray(d)) {
      d.forEach(loop)
    } else if (isMilestone(d)) {
      if (!startDate || (!!d.date && dayjs(d.date).isBefore(startDate))) {
        startDate = d.date
      }
      if (!endDate || (d.date && dayjs(d.date).isAfter(endDate))) {
        endDate = d.date
      }
    } else {
      if (!startDate || (!!d.startDate && dayjs(d.startDate).isBefore(startDate))) {
        startDate = d.startDate
      }
      if (!startDate || (!!d.endDate && dayjs(d.endDate).isBefore(startDate))) {
        startDate = d.endDate
      }
      if (!endDate || (!!d.startDate && dayjs(d.startDate).isAfter(endDate))) {
        endDate = d.startDate
      }
      if (!endDate || (!!d.endDate && dayjs(d.endDate).isAfter(endDate))) {
        endDate = d.endDate
      }
      if (isGroup(d)) loop(d.children)
    }

    if(enableExpendToNow && dayjs().isBefore(dayjs(startDate))) {
      startDate = dayjs().format('YYYY-MM-DD')
    } else if(enableExpendToNow && dayjs().isAfter(dayjs(endDate))) {
      endDate = dayjs().format('YYYY-MM-DD')
    }
  }
  loop(data)
  //如果起始时间或截至时间为空,则初始化为今天
  if(!startDate) startDate = dayjs().$format()
  if(!endDate) endDate = dayjs().$format()

  const EXT_DAYS = 21

  switch (viewType) {
    case ViewType.Month:
      /**
       * 起始总是从月初开始
       * 方便 month 视图数据处理
       */
      startDate = dayjs(startDate)
        .startOf('month')
        .$format()
      /**
       * 末尾持续到几天后的月尾。同样是方便 month 视图处理
       * HACK: 这里和 gantt-milestone 组件还略有关系；因为该组件允许里程碑标题超出组件宽度限制，所以如果末尾留的位置不够，会导致 x-scroll-container 横向滚动出 bug
       */
      endDate = dayjs(endDate)
        .add(3, 'day')
        .endOf('month')
        .$format()
      break

    default:
      /**
       * 理由同上
      */
      startDate = dayjs(startDate)
        .add(-EXT_DAYS, 'day')
        .$day(1)
        .$format()

      endDate = dayjs(endDate)
        .add(EXT_DAYS, 'day')
        .$day(7)
        .$format()
      break
  }
  //如果起始时间日期大于25,则设置为25
  if(dayjs(startDate).date() > 25) startDate = dayjs(startDate).add(-7, 'day').$format()
  //如果起始时间日期大于25,则设置为25
  if(dayjs(endDate).date() < 5) endDate = dayjs(endDate).add(7, 'day').$format()

  return [startDate!, endDate!]
}

/**
 * 将 startDate、endDate 范围的日子，补充到 range
 */
function complementRange(range: string[], startDate: string, endDate: string) {
  if (range.length) {
    const oldStart = range[0]
    for (
      let d = dayjs(oldStart).add(-1, 'day');
      d.isSameOrAfter(startDate);
      d = d.add(-1, 'day')
    ) {
      range.unshift(d.$format())
    }
    const oldEnd = range[range.length - 1]
    for (
      let d = dayjs(oldEnd).add(1, 'day');
      d.isSameOrBefore(endDate);
      d = d.add(1, 'day')
    ) {
      range.push(d.$format())
    }
  } else {
    for (
      let d = dayjs(startDate);
      d.isSameOrBefore(endDate);
      d = d.add(1, 'day')
    ) {
      range.push(d.$format())
    }
  }
}

const today = dayjs().$format()

export default Vue.extend({
  name: 'GanttChart',
  components: { GanttLayout },
  filters: {
    formatDate(date: string) {
      return dayjs(today).year() === dayjs(date).year()
        ? dayjs(date).format('MM-DD')
        : date
    },
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    data: {
      type: Array as PropType<GanttData>,
      required: true,
    },
    activeRowPos: {
      type: Number,
      required: true
    },
    dragData: {
      type: Object as PropType<{ node: GanttNode | null; movedCols: number }>,
      required: true,
    },
    resizeData: {
      type: Object as PropType<{ node: GanttNode | null; resizedCols: number; category: string }>,
      required: true,
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  data: () => ({
    weekdays: {} as DayData,
    years: new Set<string | number>(),
    dates: [] as string[],
    viewTypeOptions,
    today,
    hoveringNode: {
      isMilestone: false,
      visible: false,
      left: 0,
      width: 0,
      originDate: { start: '', end: '' },
      date: { start: '', end: '' },
    } as HoveringNode,
    scrollTop: 0,
    isSelfScroll: false,
  }),
  computed: {
    hasToday(): boolean {
      return this.dates.includes(this.today)
    },
    viewType: {
      get() {
        return this.bus.viewType
      },
      set(v: ViewType) {
        this.bus.viewType = v
        const { ee } = this.bus
        ee.emit(ee.Event.ChangeViewType, v)
      },
    },
    dayMode(): boolean {
      return this.viewType === ViewType.Day
    },
    weekMode(): boolean {
      return this.viewType === ViewType.Week
    },
    monthMode(): boolean {
      return this.viewType === ViewType.Month
    },
    weeks(): string[] {
      const { dates } = this
      let result:string[] = []
      for (let i = 0; i < dates.length; i += 7) {
        const e = dates[i + 6] || ''
        result.push(`${dates[i]} ~ ${e}`)
      }

      return result
    },
    months(): string[] {
      const { dates } = this
      const months = dates.map((d) => dayjs(d).format('YYYY-MM'))
      const result = _uniq(months)

      return result
    },
    contentStyle(): ContentStyle {
      const { colW } = this.bus
      let result = {
        width: this.dates.length * colW + 'px',
        backgroundSize: colW + 'px',
      }
      if (this.viewType === ViewType.Week) {
        result.backgroundSize = colW * 7 + 'px'
      } else if (this.viewType === ViewType.Month) {
        // 因为每月的天数是不固定的，所以不使用 background-size 的方式
        result.backgroundSize = '0px'
      }
      return result
    },
    monthStyle(): (m: string) => MonthStyle {
      // 月的宽度根据当月的天数决定
      const { colW } = this.bus

      return (m: string) => {
        const numberOfDays = this.dates.filter((d) => d.startsWith(m)).length

        return {
          width: colW * numberOfDays + 'px',
        }
      }
    },
    weekStyle(): () => WeekStyle {
      // 月的宽度根据当月的天数决定
      const { colW } = this.bus

      return () => {
        return {
          width: colW * 7 + 'px',
        }
      }
    },
    hoveringNodeStyle() {
      return {
        left: this.hoveringNode.left + 'px',
        width: this.hoveringNode.width + 'px',
      }
    },
    cptMilestoneStyle() {
      let _ = this
      return function(d) {
        return {
          backgroundColor: d && d.color ? d.color : _.barColor
        }
      }
    },
    layoutData(): GanttLayoutData {
      return transform(this.data, this.dates, this.bus.collapsedMap)
    },
    isToday(): (date: string) => boolean {
      return (date: string) => this.today === date
    },
    isCurrentMonth(): (month: string) => boolean {
      return (month: string) => dayjs(this.today).format('YYYY-MM') === month
    },
    dragPosition(): Set<string> {
      const set = new Set<string>()
      if (this.dragData.node) {
        const { node, movedCols } = this.dragData
        if (isMilestone(node)) {
          set.add(dayjs.$add(node.date, movedCols))
        } else {
          for (
            let d = dayjs(node.startDate);
            d.isSameOrBefore(node.endDate);
            d = d.add(1, 'day')
          ) {
            set.add(d.add(movedCols, 'day').$format())
          }
        }
      } else if (this.resizeData.node) {
        const { node, resizedCols, category } = this.resizeData

        if (isMilestone(node)) {
          set.add(dayjs.$add(node.date, resizedCols))
        } else {      
          if(category == 'start') {
            const startDate = dayjs.$add(node.startDate, resizedCols)
            for (
              let d = dayjs(startDate);
              d.isSameOrBefore(node.endDate);
              d = d.add(1, 'day')
            ) {
              set.add(d.$format())
            }
          } else {
            const endDate = dayjs.$add(node.endDate, resizedCols)
            for (
              let d = dayjs(node.startDate);
              d.isSameOrBefore(endDate);
              d = d.add(1, 'day')
            ) {
              set.add(d.$format())
            }
          }
        }
      }
      return set
    },
    /**
     * 记录当天的里程碑（只取其中一个）
     */
    milestoneMap(): Record<string, GanttMilestone> {
      const result = {} as Record<string, GanttMilestone>
      function loop(data: GanttData) {
        data.forEach((d) => {
          if (isMilestone(d)) {
            result[d.date] = d
          } else if (isGroup(d)) {
            loop(d.children)
          }
        })
      }
      loop(this.data)
      return result
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.complementDates()
      },
    },
    dates: {
      handler(range: string[]) {
        // FIXME: 持续时间不会跨三年吧
        const startYear = dayjs(range[0]).year() // undefined 则是今年
        this.getWeekdays(startYear)
        const endYear = dayjs(range[range.length - 1]).year()
        this.getWeekdays(endYear)
      },
      immediate: true,
    },
    viewType() {
      // 不同的视图需要展示的日期范围可能是不同的，所以切换视图时重置
      this.dates = []
      this.complementDates()
    },
  },
  created() {
    const { ee } = this.bus
    ee.on(ee.Event.ScrollTo, ({ x, y }: { x: number; y: number }) => {
      let lastX: number | undefined
      let lastY: number | undefined
      const xScrollContainer = this.$refs.xScrollContainer as HTMLElement
      const scroll = () => {
        const curX = xScrollContainer.scrollLeft
        const curY = this.scrollTop
        if (lastX === curX && lastY === curY) {
          // 上次滚动失效，说明滚动目标已经溢出可滚动范围了
          return
        }
        lastX = curX
        lastY = curY
        const diffX = x - curX
        const diffY = y - curY
        let targetX: number, targetY: number
        const speed = 0.2
        const threshold = 5
        if (Math.abs(diffX) < threshold && Math.abs(diffY) < threshold) {
          targetX = x
          targetY = y
        } else {
          targetX = Math.round(curX + diffX * speed)
          targetY = Math.round(curY + diffY * speed)
          requestAnimationFrame(scroll)
        }
        //此处修改y的坐标会导致甘特图在y轴上跳动不应该修改
        xScrollContainer.scrollLeft = targetX
      }
      scroll()
    })

    ee.on(ee.Event.StartHover, ({ id, x, w }: any) => {
      const [node] = search(id, this.data) as [GanttNode]

      let minWidth = dateMinWidth.other // 给定一个最小宽度，否则日期比较长时无法正常展示
      let date = {} as HoveringNode['originDate']

      let aIsMilestone = false

      if (isMilestone(node)) {
        minWidth = dateMinWidth.milestone
        date.start = node.date
        aIsMilestone = true
      } else {
        date = {
          start: node.startDate,
          end: node.endDate,
        }
      }

      const width = w < minWidth ? minWidth : w

      // 使居中
      const left = x - (width - w) / 2

      this.hoveringNode = {
        isMilestone: aIsMilestone,
        visible: true,
        left,
        width,
        originDate: { ...date },
        date,
      }
    })

    ee.on(ee.Event.EndHover, () => {
      this.hoveringNode.visible = false
    })

    ee.on(
      ee.Event.Drag,
      ({
        movedCols,
        dataInPx,
      }: {
        movedCols: number
        dataInPx: { [key: string]: number }
      }) => {
        if (!this.monthMode) return

        const minWidth =
          dateMinWidth[this.hoveringNode.isMilestone ? 'milestone' : 'other']

        const date = this.hoveringNode.date

        date.start = dayjs.$add(this.hoveringNode.originDate.start, movedCols)

        if (!this.hoveringNode.isMilestone) {
          date.end = dayjs.$add(this.hoveringNode.originDate.end, movedCols)
        }

        const width = dataInPx.w < minWidth ? minWidth : dataInPx.w
        const left = dataInPx.x - (width - dataInPx.w) / 2

        this.hoveringNode = {
          ...this.hoveringNode,
          visible: true, // drag 时即便不再 hover 也依然显示当前节点日期
          width,
          left,
          date,
        }
      },
    )

    ee.on(
      ee.Event.Resize,
      ({
        resizedCols,
        dataInPx,
        category,
      }: {
        resizedCols: number
        dataInPx: { [key: string]: number },
        category: string
      }) => {
        if (!this.monthMode) return
        const date = this.hoveringNode.date

        if (this.hoveringNode.isMilestone) {
          date.start = dayjs.$add(
            this.hoveringNode.originDate.start,
            resizedCols,
          )
        } else {          
          let attr = category == 'start' ? 'start' : 'end'
          date[attr] = dayjs.$add(this.hoveringNode.originDate[attr], resizedCols)         
        }

        this.hoveringNode = {
          ...this.hoveringNode,
          visible: true, // resize 时即便不再 hover 也依然显示当前节点日期
          width: dataInPx.w,
          date,
        }
      },
    )

    ee.on(ee.Event.DragEnd, () => {
      this.hoveringNode.visible = false
    })

    ee.on(ee.Event.ResizeEnd, () => {
      this.hoveringNode.visible = false
    })

    ee.on(ee.Event.GridScrollY, this.setScrollTo)
  },
  mounted() {
    setTimeout(() => {
      this.scrollToToday()
    }, 0)
  },
  methods: {
    complementDates() {
      const { data } = this
      // FIXME: 应当限制日期最长范围，防止程序崩溃。需要进行性能测试
      const [startDate, endDate] = getRange(data, this.viewType, this.bus.enableExpendToNow)
      complementRange(this.dates, startDate, endDate)
    },
    isRestDay(date: string) {
      return date in this.weekdays && isRestDay(this.weekdays[date].type)
    },
    getDesc(date: string) {
      return date in this.weekdays ? this.weekdays[date].desc : ''
    },
    getDayContent(date: string) {
      switch (this.viewType) {
        case ViewType.Week: {
          const map = ['日', '一', '二', '三', '四', '五', '六']
          return map[dayjs(date).day()]
        }
        case ViewType.Day:
        default:
          return date.slice(8)
      }
    },
    setScrollTo(scrollTop) {
      let container = (this.$refs.yScrollContainer as HTMLElement)
      if(!container) return

      let scrollLeft = container.scrollLeft
      container.scrollTo(scrollLeft, scrollTop)
    },
    onScroll(e: { target: HTMLElement }) {
      if(!this.isSelfScroll) return
      
      this.$emit('update:activeRowPos', -100)
      this.scrollTop = e.target.scrollTop
      const { ee } = this.bus

      ee.emit(ee.Event.ChartScrollY, e.target.scrollTop)
    },
    async getWeekdays(year: number | string) {
      if (this.years.has(year)) return
      try {
        this.weekdays = {
          ...this.weekdays,
          ...(await getWeekdays(year)),
        }
        this.years.add(year)
      } catch (error) {
        console.error(error)
      }
    },
    scrollToMilestone(id: string) {
      const { ee } = this.bus
      ee.emit(ee.Event.ScrollToNode, id)
    },
    scrollToToday() {
      let container = this.$refs.xScrollContainer as HTMLElement
      if(!container) return

      let el = container.querySelector('.dates .date.today')
      if(!el) return

      let index = Number(el.getAttribute('data-index')),
        scrollLeft = (index + 1) * this.bus._colW - container.clientWidth/2
      
      container.scrollLeft = scrollLeft
    },
    activeRow(activeRowPos) {
      this.$emit('update:activeRowPos', activeRowPos)
    },
    leaveRow() {
      this.$emit('update:activeRowPos', -100)
    }
  },
})
