import { getLexer, getParser, getInterpreter } from './calc.ext'

let FormulaCalc = function() {
  this.lexer = getLexer.apply(this)
  this.parser = getParser.apply(this)
  this.interpreter = getInterpreter.apply(this)
  
  let $this = this
  this.parse = function(t, e) {
    $this.calcParser = e
    t = $this.lexer.tokenize(t)
    if (0 < t.errors.length) {
      console.log("Lexer Errors:", t.errors)
      return null
    }

    $this.parser.input = t.tokens
    t = $this.parser.expr()
    if (0 < $this.parser.errors.length) {
      console.log("Parser Errors:", $this.parser.errors)
      return null
    }
      
    try {
      return $this.interpreter.visit(t)
    } catch (t) {
      console.log("Visit Error:", t)
      throw t
    }
  }

  return this
}

export default FormulaCalc