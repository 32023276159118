


































import VXETable from 'vxe-table'
import Vue, { PropType } from 'vue'

import {
  GanttData,
  Bus,
  GridColumn
} from '@/plugins/gantt/utils/types'

export default Vue.extend({
  name: 'GanttGrid',
  props: {
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    data: {
      type: Array as PropType<GanttData>,
      required: true,
    },
    activeRowPos: {
      type: Number,
      required: true
    },
    groupColumn: Object,
    columns: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    dragging: false,
    isSelfScroll: false,
  }),
  computed: {
    columnWidth() {
      return function(column: GridColumn) {
        return column.width || ''
      }
    }
  },
  watch: {
    groupColumn: {
      handler(nv) {
        if(!nv) return
        //@ts-ignore
        this.initVXERender([nv])
      },
      immediate: true
    },
    columns: {
      handler(nv){
        if(!Array.isArray(nv)) return
        //@ts-ignore
        this.initVXERender(nv)
      },
      immediate: true
    }
  },
  created() {
    const { ee } = this.bus

    ee.on(ee.Event.ChartScrollY, this.setScrollTo)
  },
  methods: {
    initVXERender(columns: GridColumn[]) {
      let obj = {}
      columns.forEach((n: GridColumn, nIndex: number) => {
        if(!!n.render) obj[n.render.name] = n.render.options
      })
      
      VXETable.renderer.mixin(obj)
    },
    setScrollTo(scrollTop) {
      if(!this.$refs.grid) return

      let { scrollLeft } = (this.$refs.grid as any).getScroll() as any
      (this.$refs.grid as any).scrollTo(scrollLeft, scrollTop)
    },
    onScroll(data: any) {
      if(!data.isY) return
      if(!this.isSelfScroll) return

      this.$emit('update:activeRowPos', -100)
      const { ee } = this.bus
      ee.emit(ee.Event.GridScrollY, data.scrollTop)      
    },
    onRowExpand(data) {
      let treeConfig = this.bus.grid.treeConfig as any
      if(!treeConfig || !treeConfig.parentField) return

      let ids = this.data
        .filter(d => d[treeConfig.parentField] == data.row.id)
        .map(d => d.id)

      const { ee } = this.bus
      ee.emit(ee.Event.ExpandNode, ids, data.expanded)
    },
    toggleTreeExpand(row) {
      let treeConfig = this.bus.grid.treeConfig as any
      if(!treeConfig || !treeConfig.parentField) return
      //@ts-ignore
      this.$refs.grid.toggleTreeExpand(row)
      //@ts-ignore
      let isExpandByRow = this.$refs.grid.isTreeExpandByRow(row)
      let ids = this.data
        .filter(d => d[treeConfig.parentField] == row.id)
        .map(d => d.id)

      const { ee } = this.bus
      ee.emit(ee.Event.ExpandNode, ids, isExpandByRow)
    },
    setAllTreeExpand() {
      (this.$refs.grid as any).setAllTreeExpand(true)
    },
    cellClick(data: any) {
      if(this.bus.grid.treeConfig && data.$event.target.className.indexOf('vxe-tree--node-btn') > -1) {
        return
      }
      //@ts-ignore
      const { ee } = this.bus

      ee.emit(ee.Event.Focus, data.row, data.$event)
      this.$emit('rowClick', data.row, data.column.property, data.$event)
    },
    cellEnter(data: any) {
      let cellY = data.$event.target.parentNode.getClientRects()[0].y,
        bodyY = data.$table.$el.querySelector('.vxe-table--body-wrapper').getClientRects()[0].y
      
      this.$emit('update:activeRowPos', cellY - bodyY)
    },
    cellLeave() {
      this.$emit('update:activeRowPos', -100)
    }
  }
})
