




















import Vue, { PropType } from 'vue'
import { GanttLayoutLeaf, Bus } from '@/plugins/gantt/utils/types'
import GanttProgress from './gantt-progress.vue'
import GanttProgressContent from './gantt-progress-content'

export default Vue.extend({
  name: 'GanttLeaf',
  components: { 
    GanttProgress, 
    GanttProgressContent 
  },
  props: {
    data: {
      type: Object as PropType<GanttLayoutLeaf>,
      required: true,
    },
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  }
})
