import EventEmitter from '@/plugins/gantt/utils/event-emitter'

export interface BaseItem {
  id: string
  name: string
  disableDraggle: boolean
}

export interface BaseGroup extends BaseItem {
  children: BaseNode[]
}

export interface BaseMilestone extends BaseItem {
  done: boolean
}

export type BaseNode = BaseItem | BaseGroup | BaseMilestone

/**
 * prop 类型；外部数据
 */
// TODO: 移除 Gantt 前缀
export interface GanttPropItem extends BaseItem {
  startDate: string
  endDate: string
  progress: number // 0 - 100
  instance: object 
  style?: string
  color?: string
}
// REVIEW: 移除 GanttProp 类型，保留 GanttPropGroup => GanttGroupRaw 以示区别
export interface GanttPropGroup extends BaseGroup {
  startDate: string
  endDate: string
  progress: number // 0 - 100
  /*startDate?: string
  endDate?: string
  progress?: number // 0 - 100*/
  children: GanttPropData
  instance: object //原始数据,用于渲染左侧grid
  style?: string
  color?: string
}

export interface GanttPropMilestone extends BaseMilestone {
  date: string
  instance: object
  style?: string
  color?: string
}

export type GanttPropNode = GanttPropItem | GanttPropGroup | GanttPropMilestone

export type GanttPropData = GanttPropNode[]

/**
 * 转换后的内部数据
 */
// TODO: GanttItem => DataItem
export type GanttItem = GanttPropItem

export interface GanttGroup extends GanttItem, BaseGroup {
  children: GanttData
}

export type GanttMilestone = GanttPropMilestone

export type GanttNode = GanttItem | GanttGroup | GanttMilestone

export type GanttData = GanttNode[]

/**
 * 布局数据
 */
interface BaseLayoutItem {
  x: number
  y: number
  w: number
  h: number
}

// TODO: 移除 Gantt 前缀
export interface GanttLayoutLeaf extends BaseItem, BaseLayoutItem {
  instance: object
  progress: number
  style?: string
  color?: string
}

export interface GanttLayoutGroup extends GanttLayoutLeaf, BaseGroup {
  instance: object
  children: GanttLayoutData
  style?: string
  color?: string
}

export interface GanttLayoutMilestone extends BaseMilestone, BaseLayoutItem {
  color?: string
}

export type GanttLayoutNode =
  | GanttLayoutLeaf
  | GanttLayoutGroup
  | GanttLayoutMilestone

export type GanttLayoutData = GanttLayoutNode[]

/**
 * 记录哪些节点处于 collapsed 状态
 */
export interface CollapsedMap {
  [id: string]: boolean
}

export interface GridColumnRender {
  name: string
  options: object
}

export interface GridColumn {
  prop: string
  label: string
  width: number
  render: GridColumnRender
}

export interface GridOption {
  groupColumn?: GridColumn,
  columns: GridColumn[],
  rowClass: string,
  treeConfig: object
}

/**
 * 公交车🚌定义
 */
export interface Bus {
  isReady: boolean
  isloading: boolean
  rowH: number
  _colW: number
  colW: number
  viewType: ViewType
  enableDraggle: boolean
  enableResizeStart: boolean
  enableResizeEnd: Boolean,
  enableExpendToNow: Boolean,
  toolbar: boolean
  collapsedMap: CollapsedMap
  grid: GridOption
  ee: EventEmitter
}

export enum ViewType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

type HoveringNodeDate = {
  start: string
  end: string
}

export interface HoveringNode {
  isMilestone: boolean
  visible: boolean
  left: number
  width: number
  originDate: HoveringNodeDate
  date: HoveringNodeDate
}
