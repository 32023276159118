





















import Vue, { PropType } from 'vue'
import { GanttLayoutGroup, Bus } from '@/plugins/gantt/utils/types'
import GanttProgress from './gantt-progress.vue'
import GanttProgressContent from './gantt-progress-content'

export default Vue.extend({
  name: 'GanttGroup',
  components: {
    GanttProgress,
    GanttProgressContent,
    GanttLayout: () => import('./gantt-layout.vue'),
  },
  props: {
    data: {
      type: Object as PropType<GanttLayoutGroup>,
      required: true,
    },
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    activeRowPos: {
      type: Number,
      required: true
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  computed: {
    showChildren(): boolean {
      return !this.bus.collapsedMap[this.data.id]
    },
    contentStyle(): { height: string } {
      return {
        height: this.bus.rowH + 'px',
      }
    },
    borderStyle(): { top: string; bottom: string } {
      const half = this.bus.rowH / 2 + 'px'
      return {
        top: half,
        bottom: half,
      }
    },
  },
})
