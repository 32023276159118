var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "progress", staticClass: "gantt-progress-box" }, [
    _c(
      "div",
      {
        class: [
          "gantt-progress",
          _vm.type,
          _vm.cptOuterClass,
          _vm.cptProgressContent,
        ],
        style: _vm.cptStyle,
      },
      [
        _vm.isProcessInside
          ? _c(
              "div",
              { ref: "processContent", staticClass: "process-content-box" },
              [_vm._t("processContent")],
              2
            )
          : _vm._e(),
      ]
    ),
    !_vm.isProcessInside
      ? _c(
          "div",
          { ref: "processContent", staticClass: "process-content-box" },
          [_vm._t("processContent")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }