import FunctionHandle from './function.handle'
import FormulaCalc from './FormulaCalc'

let formulaCalc = new FormulaCalc()
let FormulaCalcParser = function(options) {
  let op = {
    recno: null
  }

  Object.assign(op, options)
  this.options = op
}

FormulaCalcParser.prototype.applyFunc = function(t, e) {
  if (FunctionHandle[t]) return FunctionHandle[t].apply(this, e)  
}

FormulaCalcParser.prototype.parse = function(t) {
  return formulaCalc.parse(t, this)
}

export default FormulaCalcParser