













import Vue, { PropType } from 'vue'
import { GanttLayoutLeaf, Bus } from '@/plugins/gantt/utils/types'

const COLOR_MAP = {
  '#FF7800': 'F9C394',
  '#F9D901': 'F7EA94',
  '#2ECD70': 'A5E5C1',
  '#35C0F1': 'BBE5F5',
  '#FC576C': 'F7C5CD',
  '#7C4DFF': 'D0C2F9',
  '#FE4082': 'F8BED3',
  '#B5BCC2': 'E2E4E7',
  '#00BDD4': 'ABE4EC',
  '#166AFF': 'B2CBF9'
}

export default Vue.extend({
  name: 'GanttProgress',
  props: {
    bus: {
      type: Object as PropType<Bus>,
      required: true,
    },
    data: {
      type: Object as PropType<GanttLayoutLeaf>,
      required: true,
    },
    type: {
      type: String as PropType<'leaf' | 'group'>,
      default: 'leaf',
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  data() {
    return {
      isProcessInside: false,
      contentWidth: 0
    }
  },
  computed: {
    cptOuterClass(): string{
      if(!COLOR_MAP[this.barColor]) return ''
      
      return `outter_${COLOR_MAP[this.barColor]}`
    },
    cptProgressContent() :string{
      if(!this.$slots.processContent) return ''
      
      return 'process-content'
    },
    cptStyle(): any {
      return {
        backgroundColor: this.barColor
      }
    }
  },
  mounted(){
    if(this.$slots.processContent) {      
      this.contentWidth = (this.$refs.processContent as HTMLElement).clientWidth
    }

    this.setProcessContentPosition()

    const { ee } = this.bus
    ee.on(ee.Event.ResetNode, (id: string) => {     
      if (id !== this.data.id) return
      if(this.data.w == 0) return

      this.resetPosition()
    })
    
    ee.on(ee.Event.ResizeEnd, this.resetPosition)
  },
  methods: {
    setProcessContentPosition() {
      if(!this.$slots.processContent) return

      const { colW } = this.bus
      const { w } = this.data

      let bWidth = w != 0 ? Math.max(colW, w * colW) : 0,
        bPadding = 12 * 2

      this.isProcessInside = (bWidth >= (this.contentWidth + bPadding))
    },
    resetPosition() {
      //拖拽后避免由于拖拽到非整数列导致的重新调整造成的渲染延迟
      setTimeout(() => {
        this.setProcessContentPosition()
      }, 200)
    }
  }
})
