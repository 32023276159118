
























import Vue, { PropType } from 'vue'
import { GanttLayoutMilestone } from '@/plugins/gantt/utils/types'

export default Vue.extend({
  name: 'GanttMilestone',
  props: {
    data: {
      type: Object as PropType<GanttLayoutMilestone>,
      required: true,
    },
    barColor: {
      type: String,
      default: '#307fe2'
    }
  },
  computed: {
    cptStyleDone(): any {
      return {
        backgroundColor: this.barColor,
        borderColor: this.barColor,
      }
    },
    cptStyle(): any {
      return {
        borderColor: this.barColor,
      }
    },
  }
})
